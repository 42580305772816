import React, { FC, Fragment, useCallback } from 'react';
import cn from 'classnames';
import Link from '@components/NextLink';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Navbar from '@components/Navbar';
import Disclaimer from '@components/Disclaimer';

import type { TRouter } from '@local-types/global';
import useGlobals from '@hooks/useGlobals';

import styles from './Header.module.scss';
import useMobile from '@hooks/useMobile';

type THeader = {
  withDisclimer?: boolean;
  articles?: any;
  activeUrl?: string;
  isCompanyManagementPage?: boolean;
};

const Header: FC<THeader> = ({
  withDisclimer,
  articles,
  activeUrl,
  isCompanyManagementPage,
}) => {
  const router = useRouter();
  const { locale, locales } = router as TRouter;
  const [
    { toggleIsDarkTheme, toggleSidebar },
    { isDarkTheme, isOpenedSidebar },
  ] = useGlobals();
  const { isMobile } = useMobile()[1];
  const handleToggleTheme = useCallback(() => {
    toggleIsDarkTheme();
  }, []);

  const handleToggleSidebar = useCallback(() => {
    toggleSidebar();
  }, []);

  return (
    <Fragment>
      <header
        className={cn(styles.header, {
          [styles.darkTheme]: isDarkTheme,
          [styles.openedSidebar]: isOpenedSidebar,
          [styles.companyManagementPage]: isCompanyManagementPage,
        })}
      >
        {isCompanyManagementPage && (
          <div>
            {!isMobile && (
              <Link href="/" locale={locale} shallow={false}>
                <Image
                  src={
                    isDarkTheme
                      ? '/assets/logos/keepsimpleDark.svg'
                      : '/assets/logos/keepsimple-company-mngmnt.svg'
                  }
                  alt="keepsimple logo"
                  width={130.61}
                  height={32}
                  className={styles.logoOnCompanyManagementPage}
                />
              </Link>
            )}
          </div>
        )}
        <div className={styles.logo}>
          <Link href="/" locale={locale} shallow={false}>
            <Image
              src={
                isDarkTheme
                  ? '/assets/logos/keepsimpleDark.svg'
                  : '/assets/logos/keepsimple-company-mngmnt.svg'
              }
              alt="keepsimple logo"
              width={200}
              height={39}
              className={styles.logo}
            />
          </Link>
        </div>
        <div className={styles.burgerMenu} onClick={handleToggleSidebar}>
          <div />
          <div />
          <div />
        </div>
        <div>
          <div className={styles.siteName}>
            <Link href="/" locale={locale} shallow={false}>
              <Image
                src={
                  isDarkTheme
                    ? '/assets/logos/keepsimpleDark.svg'
                    : '/assets/logos/keepsimple-company-mngmnt.svg'
                }
                alt="keepsimple logo"
                width={130.61}
                height={25.87}
                className={styles.logo}
              />
            </Link>
          </div>
          <Navbar
            articles={articles}
            activeUrl={activeUrl}
            isCompanyManagementPage={isCompanyManagementPage}
          />
          <div className={styles.actions}>
            <div
              className={styles.toggleTheme}
              onClick={handleToggleTheme}
            ></div>
            <div
              className={cn(styles.toggleLanguage, {
                [styles.en]: locale === 'en',
                [styles.ru]: locale === 'ru',
              })}
            >
              {locale === 'en' ? (
                <Link shallow={false} href={router.asPath} locale={locales[1]}>
                  <a className={styles.languageTitle}>
                    <Image
                      src={
                        isDarkTheme
                          ? '/assets/globe-light.png'
                          : '/assets/globe-dark.png'
                      }
                      width={24}
                      height={24}
                    />
                    {locales[1]}
                  </a>
                </Link>
              ) : (
                <Link shallow={false} href={router.asPath} locale={locales[0]}>
                  <a className={styles.languageTitle}>
                    <Image
                      src={
                        isDarkTheme
                          ? '/assets/globe-light.png'
                          : '/assets/globe-dark.png'
                      }
                      width={24}
                      height={24}
                    />
                    {locales[0]}
                  </a>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className={styles.closeButton} onClick={handleToggleSidebar} />
      </header>
      {withDisclimer && <Disclaimer />}
    </Fragment>
  );
};

export default Header;
